<template>
  <div class="searcher">
    <SearchField
      :searchParams.sync="searchParams"
      v-bind="{ ...$props, ...$attrs }"
      :api="searchApi"
    />
    <b-skeleton v-if="loading && !totalLoaded" width="100" />
    <div v-else class="totalItems" v-html="totalItemsString" />
    <Infinite-scroll
      :api="searchApi"
      :params="searchParams"
      :internalParams="filterOptions.params"
      @update="onUpdate"
      :totalItems.sync="totalItems"
      :loading="loading"
      :totalLoaded.sync="totalLoaded"
      :dataPreProcesor="dataPreProcesor"
      ref="InfiniteScroll"
    >
      <template #default="{ data }">
        <slot :data="data" />
      </template>
    </Infinite-scroll>
  </div>
</template>

<script>
import InfiniteScroll from '@/components/InfiniteScroll.vue';
import SearchField from '@/components/SearchField.vue';
export default {
  components: {
    InfiniteScroll,
    SearchField
  },
  data() {
    return {
      filter: {},
      filters: this.filterOptions.data || [],
      loading: false,
      searchParams: [],
      search: this.$route.query.search,
      totalItems: 0,
      totalLoaded: 0
    };
  },
  computed: {
    searchApi() {
      const { api } = this;
      if (typeof api === 'function') return api(this.searchParams);
      return api;
    },
    totalItemsString() {
      return this.$tc('global.results', this.totalItems || 0);
    },
    URLParams() {
      return this.$route.query || {};
    },
    resultsMessage() {
      return this.$i18n.t('global.countFTO', [1, 10, 160]);
    }
  },
  methods: {
    filterById(id) {
      return this.filters.filter((f) => f.id == id)[0];
    },
    onUpdate(data) {
      this.$emit('updated', data);
    },
    reloadInfo() {},
    onRemoveById(id) {
      return this.$refs.InfiniteScroll.onRemoveById(id);
    }
  },
  props: {
    api: { type: [Function, String], required: true },
    dataPreProcesor: { type: Function, default: null },
    filterOptions: {
      type: Object,
      default: () => ({ label: '', api: '', data: [], field: '', model: '' })
    },
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    queryKey: { type: String, default: 'all' },
    useDropdown: { type: Boolean, default: true }
  }
};
</script>

<style lang="sass" scoped>
.searcher
  // @include scroll-bar
  height: calc(100vh - 90px)
  border-radius: $br-md
  overflow: hidden
.search-field
  margin-bottom: 10px
.infinite-scroll
  height: calc( 100% - 65px )
  overflow: auto
  ::v-deep
    .card
      margin: 0 0 8px
      padding: 15px 25px 5px
      border: 1px solid $gray-300
      &:hover
        box-shadow: none
        border-color: $gray-500!important
        background: lighten($primary,37)
      &.active
        background: $gray-50
        border: 1px solid $gray-500!important
      &.disabled
        opacity: 0.3
</style>
<style lang="sass">
.dark
  .searcher
    .infinite-scroll
      .card
        &.p-card-il
          border: 1px solid $gray-700
          p
            color: $main-background
          .role-tag
            div
              background: $light-blue-900
              p
                color: $light-blue-100
        &.active
          background-color: $dark-700 !important
</style>
