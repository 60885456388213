<template>
  <Card class="p-card-il" :class="active ? 'active' : ''" @click="onCardClick">
    <div class="letters-container" :class="disabled">
      <div class="letters flex f-ai f-jc">{{ letters }}</div>
    </div>
    <div class="details" :class="disabled">
      <div class="header">
        <strong>{{ cdp.name }}</strong>
      </div>
      <p>
        {{ cdp.full_address }}
      </p>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/Card.vue';

export default {
  components: {
    Card
  },
  computed: {
    disabled() {
      return this.cdp.is_active ? '' : 'is-disabled';
    },
    letters() {
      const { name } = this.cdp;
      let lettersArr = name.split(' ').map((l) => l[0]);
      return lettersArr.length > 2 ? lettersArr[0] + lettersArr[1] : lettersArr.join('');
    }
  },
  methods: {
    onCardClick() {
      this.$emit('click', this.cdp);
    }
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    cdp: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="sass" scoped>
$pictureSize: 72px
.card
  margin: 0 0 8px
  padding: 15px 25px
  border: 3px solid transparent
  &:hover
    background: lighten($primary,37)
  &.active
    border-color: $primary
    background: lighten($primary,41)
.p-card-il
  ::v-deep
    .card-content
      padding: 0
      display: flex
.letters-container
  height: $pictureSize
  width: $pictureSize
  margin-right: 25px
  border: 2px solid $gray-200
  border-radius: $br-md
  background: $gray-100
  .letters
    width: $pictureSize
    height: $pictureSize
    font-size: $f-xxl
    color: $gray-700
.details
  width: 100%
  .header
    strong
      font-size: $f-lg
  p
    margin-bottom: 5px
</style>

<style lang="sass">
.dark
  .letters-container
    background: $gray-800
    border: 2px solid $gray-200
    .letters
      color: $gray-200
</style>
